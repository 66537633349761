import React from 'react';
import './Home.css';

class Home extends React.Component {

  componentDidMount() {
    var context = new AudioContext();
    var audioElement = document.querySelector(".player"); // generate from state
    const audSet  = this.props.audioSettings;
    audSet.element = audioElement;
    this.setState({audioSettings: audSet});
    var source = context.createMediaElementSource(audioElement);
    source.connect(context.destination);


    var analyser = context.createAnalyser();
    analyser.fftSize = 256;

    source.connect(analyser);
    analyser.connect(context.destination);
    var bufferLength = analyser.frequencyBinCount;


    var dataArray = new Uint8Array(bufferLength);
    console.log(dataArray.length);
    analyser.getByteFrequencyData(dataArray);

    var canvas = document.getElementById("canvas");
    canvas.width = 500;
    canvas.height = 100;
    var ctx = canvas.getContext("2d");

    var WIDTH = canvas.width;
    var HEIGHT = canvas.height;

    var barWidth = (WIDTH / bufferLength)/3;
    var barHeight = 1;
    var cutOff = 0;
    var gap = WIDTH / (bufferLength - cutOff);
    var x = 0;
    ctx.fillStyle = "#000";
    ctx.fillRect(0, 0, WIDTH, HEIGHT);

    function update() {
      requestAnimationFrame(update);
      x = 0;

          analyser.getByteFrequencyData(dataArray);

          ctx.fillStyle = "#000";
          ctx.fillRect(0, 0, WIDTH, HEIGHT);

          for (var i = 0; i < bufferLength; i++) {

            if (i >= cutOff ) {
              barHeight = dataArray[i]/4;

              var r = 5;
              var g = 5;
              var b = 255;

              ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
              ctx.fillRect(x, HEIGHT, barWidth, -barHeight);

              x += barWidth + gap*3;
            }

          }
        }

        update();

        document.querySelector('#canvas').addEventListener('click', function() {
          context.resume().then(() => {
            console.log('Playback resumed successfully');
          });
        });
  }

  switchSource = () => {
    if (this.props.audioSettings.isPlaying) {
      this.props.toggleStream();
    }

    if (this.props.audioSettings.element.src === this.props.audioSettings.src.stream) {
      this.props.audioSettings.element.src = this.props.audioSettings.src.file;
    } else {
      this.props.audioSettings.element.src = this.props.audioSettings.src.stream;
    }

    this.props.toggleStream();
    this.props.changeActiveSrc();
  }

  render () {
    return (
      <div className="audio-player">
        <canvas id="canvas"></canvas>
        <div className="content-wrapper">
          <div className="controls">
            <button id='file_button' onClick={this.props.toggleStream}>{this.props.audioSettings.isPlaying ? 'Pause' : 'Play'}</button>
            <button className="switch-source" onClick={this.switchSource}>Switch Source to
              <span>{this.props.audioSettings.activeSrc === 'file' ? ' Stream' : ' File'}</span>
            </button>
            <audio
              crossOrigin="anonymous"
              className="player"
              controls={this.props.audioSettings.controls}
              autoPlay={this.props.audioSettings.autoplay}>
              <source src={this.props.audioSettings.src.file} type="audio/mpeg">
              </source>
              Your browser does not support the audio tag.
            </audio>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;
