import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';
import Home from './Home';
import './App.css';

class App extends Component {

  state = {
    audioSettings: {
      activeSrc: 'file',
      controls: false,
      loop: true,
      autoplay: false,
      isPlaying: false,
      src: {
        stream: 'https://kcrw.streamguys1.com/kcrw_192k_mp3_on_air',
        file: 'assets/Testing.mp3'
      },
      element: {}
    }
  }

  componentDidMount() {

  }

  toggleStream = () => {
    if (this.state.audioSettings.isPlaying === false) {
      this.state.audioSettings.element.play();
      const as = this.state.audioSettings;
      as.isPlaying = true;
      this.setState({audioSettings: as});
    } else {
      this.state.audioSettings.element.pause();
      const as = this.state.audioSettings;
      as.isPlaying = false;
      this.setState({audioSettings: as});
    }
  }

  stopStream = () => {

  }

  changeActiveSrc = () => {
    const as = this.state.audioSettings;
    if (as.activeSrc === 'file') {
      as.activeSrc = 'stream';
      this.setState({audioSettings: as});
    } else {
      as.activeSrc = 'file';
      this.setState({audioSettings: as});
    }

  }

  render() {
    return (
      <Router>
        <div className="App">

          <nav className="navigation">
            <ul>
              <li><Link to={'/'}>Home</Link></li>
              <li><Link to={'/calendar'}>Calendar</Link></li>
            </ul>
          </nav>

          <main className="main-content">
            <Route exact path="/" render={ () =>
              <Home
                toggleStream={this.toggleStream}
                changeActiveSrc={this.changeActiveSrc}
                {...this.state}
              />
            }/>
          </main>


        </div>
      </Router>
    );
  }
}

export default App;
